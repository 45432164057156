<template>
  <div class="tw-absolute tw-h-full tw-w-full">
    <svg ref="svg" class="tw-h-full tw-w-full" />
    <slot />
  </div>
</template>

<script lang="ts" setup="props, { emit }">
import {
  arc,
  hierarchy,
  interpolate,
  partition,
  scaleOrdinal,
  schemeDark2,
  select,
  Selection
} from "d3";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { TextBox } from "d3plus-text";
import { computed, onMounted, ref, SetupContext } from "vue";
import { onResize } from "@/composables/resize";
import router from "@/router";

declare const props: {
  hierarchy: SunBurstNode;
  getText: (d: SunBurstNodePosition) => string;
  getShortText: (d: SunBurstNodePosition) => string;
  colors?: string[];
  selected?: any;
};

declare const emit: SetupContext["emit"];
/**
 * SVG Element.
 */
export const svg = ref<SVGElement>();

const width = ref(0);

const height = ref(0);

const selectOrCreate = <T extends Element>(
  parent: T | Selection<any, any, any, any> | undefined,
  selector: string,
  classes = ""
) => {
  if (parent) {
    if (parent instanceof Element) {
      parent = select(parent);
    }

    const selectorWithClasses = selector + (classes ? "." + classes : "");

    if (parent.select(selectorWithClasses).size() > 0) {
      return parent.select(selectorWithClasses);
    } else {
      return parent.append(selector).classed(classes, true);
    }
  }
};

const radius = computed(() => {
  return Math.min(width.value, height.value) / 7;
});

const root = computed(
  (): SunBurstNode => {
    return partition<SunBurstNodeData>()
      .size([2 * Math.PI, props.hierarchy.height + 1])(props.hierarchy)
      .each((node: SunBurstNode) => {
        node.current = node;
      });
  }
);

const colorsList = computed(() => {
  return scaleOrdinal(schemeDark2);
});

const createdArc = computed(() => {
  return arc<SunBurstNodePosition>()
    .startAngle(d => d.x0)
    .endAngle(d => d.x1)
    .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
    .padRadius(radius.value * 1.5)
    .innerRadius(d => d.y0 * radius.value)
    .outerRadius(d => Math.max(d.y0 * radius.value, d.y1 * radius.value - 1));
});

const g = computed(() => {
  return selectOrCreate(svg.value, "g", "main")?.attr(
    "transform",
    `translate(${width.value / 2},${height.value / 2})`
  );
});

const arcVisible = (d: SunBurstNodePosition) => {
  return d.y1 <= 3 && d.x1 > d.x0;
};

const labelVisible = (d: SunBurstNodePosition) => {
  return d.y1 <= 2 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
};

const path = computed(() => {
  return selectOrCreate(g.value, "g", "paths")
    ?.selectAll("path")
    .data(root.value.descendants().slice(1))
    .join("path")
    .attr("fill", d => {
      while (d.depth > 1 && d.parent) {
        d = d.parent;
      }
      return colorsList.value(d.data.name);
    })
    .attr("fill-opacity", d => {
      return d.current && arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0;
    })
    .attr("d", d => (d.current ? createdArc.value(d.current) : ""))
    .classed("tw-cursor-pointer", true)
    .on("click", (e, d) => focusOn(d))
    .on("mouseenter", (e, d) => changeTitle(d))
    .on("mouseleave", () => changeTitle(props.selected));
});

const labelTransform = (d: SunBurstNodePosition) => {
  const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
  const y = ((d.y0 + d.y1) / 2) * radius.value;
  return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
};

const label = computed(() => {
  return selectOrCreate(g.value, "g", "label")
    ?.attr("pointer-events", "none")
    .attr("text-anchor", "middle")
    .style("user-select", "none")
    .selectAll("text")
    .data(root.value.descendants().slice(1))
    .join("text")
    .attr("dy", "0.35em")
    .attr("fill-opacity", d => {
      return d.current && labelVisible(d.current) ? 1 : 0;
    })
    .attr("transform", d => (d.current ? labelTransform(d.current) : ""))
    .attr("font-size", 13);
});

const title = computed(() => {
  return selectOrCreate(g.value, "g", "title")
    ?.classed("tw-font-bold tw-cursor-pointer", true)
    .on("click", (e, d) => focusOn(d));
});

const changeTitle = (d: SunBurstNode) => {
  title.value?.datum(d ? d.parent : root.value);

  new TextBox()
    .select(title.value?.node())
    .data([props.getText(d)])
    .text((d: string) => d)
    .verticalAlign("middle")
    .textAnchor("middle")
    .x(-radius.value)
    .y(-radius.value)
    .width(radius.value * 2)
    .height(radius.value * 2)
    .padding(50)
    .fontSize(13)
    .fontResize(true)
    .fontMin(10)
    .fontMax(15)
    .fontWeight("")
    .fontFamily("")
    .fontColor("")
    .id("")
    .render();
};

const focusOn = (p: SunBurstNode = root.value) => {
  if (svg.value && path.value && g.value) {
    const {
      width: svgWidth,
      height: svgHeight
    } = svg.value?.getBoundingClientRect();
    width.value = svgWidth;
    height.value = svgHeight;
    const parent = p.parent || p;

    root.value.each(d => {
      d.target = {
        x0:
          Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
        x1:
          Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
        y0: Math.max(0, d.y0 - p.depth),
        y1: Math.max(0, d.y1 - p.depth)
      };
    });

    path.value
      .attr("pointer-events", "none")
      .transition()
      .duration(750)
      .tween("data", d => t => {
        if (d.target) {
          d.current = interpolate(d.current, d.target)(t);
        }
      })
      .filter((d, i, e) => {
        const element = e[i];
        const opacity =
          element && "getAttribute" in element
            ? Number(element.getAttribute("fill-opacity"))
            : 0;
        return (d.target && arcVisible(d.target)) || opacity > 0;
      })
      .attr("fill-opacity", d => {
        return d.target && arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0;
      })
      .attrTween("d", d => () => {
        return (d.current && createdArc.value(d.current)) || "";
      })
      .attr("fill", d => {
        while (d.depth > 1 && d.parent) {
          d = d.parent;
        }
        return colorsList.value(d.data.name);
      })
      .attr("pointer-events", "all");

    label.value
      ?.filter((d, i, e) => {
        const element = e[i];
        const opacity =
          element && "getAttribute" in element
            ? Number(element.getAttribute("fill-opacity"))
            : 0;
        return (d.target && labelVisible(d.target)) || opacity > 0;
      })
      .transition()
      .duration(750)
      .attr("fill-opacity", d => {
        return d.target && labelVisible(d.target) ? 1 : 0;
      })
      .attrTween("transform", d => () => {
        return d.current ? labelTransform(d.current) : "";
      })
      .text(props.getShortText);

    circle.value?.datum(parent);
    changeTitle(p);
    emit("select", p);
  }
};

const circle = computed(() => {
  return selectOrCreate(g.value, "g")
    ?.append("circle")
    .datum(root.value)
    .attr("r", radius.value)
    .attr("fill", "none")
    .attr("pointer-events", "all")
    .classed("tw-cursor-pointer", true)
    .on("click", (e, d) => focusOn(d));
});

const getItemFromQuery = () => {
  let current = props.hierarchy;
  const selection = router.currentRoute.value.query.selected;

  if (current && selection) {
    const parts = selection
      .toString()
      .split("-")
      .map(Number);

    for (const i in parts) {
      const part = parts[i];
      current = current.children?.[part] || current;
    }

    return current;
  }

  return current;
};

onMounted(() => focusOn(getItemFromQuery()));

onResize(() => {
  focusOn(getItemFromQuery());
});

export default {};
</script>

<style lang="sass">
.slices
  cursor: pointer

.main-arc
  stroke: #fff
  stroke-width: 1px
  .hidden-arc
    fill: none
  text
    pointer-events: none
    dominant-baseline: middle
    text-anchor: middle
</style>
