<template>
  <div
    class="tw-h-full tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center relative"
  >
    <template v-if="!loading">
      <sun-burst
        :get-short-text="getShortText"
        :get-text="getText"
        :hierarchy="nodes"
        :selected="selected"
        @open="open"
        @select="select"
      >
        <div class="tw-z-50" v-html="content"></div>
      </sun-burst>

      <!-- <div
        class="tw-top-0 tw-left-0 tw-absolute tw-rounded tw-shadow-lg tw-bg-white tw-p-8 tw-m-8 tw-text-gray-600"
      >
        <v-autocomplete
          :items="children"
          @change="focusOn"
          item-text="data.name"
          item-value="data.name"
          label="Search for an employee"
          return-object
          v-model="search"
        />
      </div> -->

      <div
        class="tw-top-0 tw-right-0 tw-absolute tw-p-8 tw-m-8 tw-text-gray-600"
      >
        <div class="tw-py-4 tw-font-bold tw-text-4xl">
          <p>{{ selected ? selected.value : data.value }}</p>
        </div>
      </div>
    </template>

    <template v-else>
      <h1>Loading...</h1>
    </template>
  </div>
</template>

<script lang="ts" setup="props, { emit }">
import SunBurst from "@/components/SunBurst.vue";
import { hierarchy } from "d3";
import { computed, ref } from "vue";

export const selected = ref();
export const search = ref("");

declare const props: {
  data: SunBurstNode;
  loading?: boolean;
  open?: () => void;
  getContent?(selected: SunBurstNode): string;
};

/**
 * Create the hierarchy
 */
export const nodes = computed(() => {
  return hierarchy<typeof props["data"]>(props.data)
    .sum(d => (d.children ? 0 : 1))
    .sort((a, b) => (b.value || 0) - (a.value || 0));
});

export const content = computed(() => {
  return props.getContent?.(selected.value) || "";
});

/**
 * List of children
 */
export const children = computed(() => {
  return nodes.value.descendants().filter(d => !d.children);
});

/**
 * Get the text.
 */
export const getText = (d: SunBurstNode) => {
  return d.data?.name;
};

/**
 * Get short text.
 */
export const getShortText = (d: SunBurstNode) => {
  return d.data?.shortname || d.data?.name;
};

/**
 * Select.
 */
export const select = (d: SunBurstNode) => {
  const path = [];
  let current = d;

  while (current.parent && current.parent.children) {
    path.push(current.parent.children.indexOf(current));
    current = current.parent;
  }

  const pathQuery = path.reverse().join("-");

  history.replaceState(null, "", `?selected=${pathQuery}`);

  selected.value = d;
};

/**
 * Get total of children
 */
export const getTotalChildren = (current: SunBurstNode) => {
  return current.children
    ? current.children.reduce((total, c) => {
        total += getTotalChildren(c);
        return total;
      }, 0)
    : 1;
};

export default { components: { SunBurst } };
</script>
