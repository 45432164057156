import { defineComponent as __define__ } from 'vue'
import { Slots as __Slots__ } from 'vue'
import SunBurst from "@/components/SunBurst.vue";
import { hierarchy } from "d3";
import { computed, ref } from "vue";


export function setup(props: {
  data: SunBurstNode;
  loading?: boolean;
  open?: () => void;
  getContent?(selected: SunBurstNode): string;
}, { emit }: {
  emit: (e: string, ...args: any[]) => void,
  slots: __Slots__,
  attrs: Record<string, any>
}) {

const selected = ref();
const search = ref("");

const nodes = computed(() => {
  return hierarchy<typeof props["data"]>(props.data)
    .sum(d => (d.children ? 0 : 1))
    .sort((a, b) => (b.value || 0) - (a.value || 0));
});

const content = computed(() => {
  return props.getContent?.(selected.value) || "";
});

/**
 * List of children
 */
const children = computed(() => {
  return nodes.value.descendants().filter(d => !d.children);
});

/**
 * Get the text.
 */
const getText = (d: SunBurstNode) => {
  return d.data?.name;
};

/**
 * Get short text.
 */
const getShortText = (d: SunBurstNode) => {
  return d.data?.shortname || d.data?.name;
};

/**
 * Select.
 */
const select = (d: SunBurstNode) => {
  const path = [];
  let current = d;

  while (current.parent && current.parent.children) {
    path.push(current.parent.children.indexOf(current));
    current = current.parent;
  }

  const pathQuery = path.reverse().join("-");

  history.replaceState(null, "", `?selected=${pathQuery}`);

  selected.value = d;
};

/**
 * Get total of children
 */
const getTotalChildren = (current: SunBurstNode) => {
  return current.children
    ? current.children.reduce((total, c) => {
        total += getTotalChildren(c);
        return total;
      }, 0)
    : 1;
};


return { selected, search, nodes, content, children, getText, getShortText, select, getTotalChildren }
}

const __default__ = { components: { SunBurst } };
export default __define__({
  ...__default__,
  props: {
    data: { type: null, required: true },
    loading: { type: Boolean, required: false },
    open: { type: Function, required: false }
  } as unknown as undefined,
  setup
})