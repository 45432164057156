import { computed, getCurrentInstance, isRef, onBeforeMount, onMounted, Ref } from 'vue'

const ctx = {
    observer: null as null | ResizeObserver | (() => void),
    observables: [] as [Element, ((element: Element) => void)][]
}

export function onResize(callback: (element: Element) => void, elementOrRef?: Element | Ref<Element | undefined>,) {
    const element = computed(() => {
        return typeof elementOrRef !== 'undefined' ? isRef(elementOrRef) ? elementOrRef.value : elementOrRef : getCurrentInstance()?.vnode.el
    })

    onMounted(() => {
        if (element.value instanceof Element) {
            if ('ResizeObserver' in window) {
                ctx.observer = ctx.observer || new window.ResizeObserver((entries) => {
                    ctx.observables.filter(([element]) => entries.find(({ target }) => target === element)).forEach(([element, callback]) => {
                        callback(element)
                    })
                })

                if (ctx.observer instanceof ResizeObserver) {
                    ctx.observer.observe(element.value)
                }
            } else {
                const hasObserver = Boolean(ctx.observer)

                ctx.observer = ctx.observer || (() => {
                    ctx.observables.forEach(([element, callback]) => {
                        callback(element)
                    })
                })

                if (!hasObserver && typeof ctx.observer === 'function') {
                    window.addEventListener('resize', ctx.observer.bind(null))
                }
            }

            ctx.observables.push([element.value, callback])
        }
    })

    onBeforeMount(() => {
        const index = ctx.observables.findIndex(([e]) => element.value === e)
        ctx.observables.splice(index, 1)
    })
}